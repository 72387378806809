import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'

import request from './axios/axios'   //axios实例化后引入取名http
import config from './config/config'

Vue.prototype.request = request
Vue.prototype.config = config
//文件预览地址
Vue.prototype.preview = config.baseUrl + "owe/file/download?download=0&id=";
//文件下载地址
Vue.prototype.download = config.baseUrl + "owe/file/download?download=1&id=";

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
