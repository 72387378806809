import axios from 'axios'
import config from '../config/config';
import {Message} from 'element-ui';

let prefix = config.baseUrl + config.baseApiUrl;

// 创建axios实例
let service = axios.create({
    baseURL: prefix,
    timeout: 5000,
    headers: {
        'content-type': 'application/json',
    }
});

// 响应拦截器
service.interceptors.response.use(res => {
        if (200 === res.status) {
            if (200 === res.data.code) {
                let {data} = res;
                return (undefined === data) ? {} : data;
            } else {
                Message({message: '请求数据异常', type: 'warning'});
                return Promise.reject(new Error("请求数据异常"));
            }
        } else {
            Message({message: '请求数据失败', type: 'warning'});
            return Promise.reject(new Error("请求数据失败"));
        }
    },
    error => {
        Message({message: error, type: 'warning'});
        return Promise.reject(new Error(error));
    }
);

export default {
    service,
    //get请求
    get(url, data) {
        return service({
            url: url.indexOf("/") ? url.substring(1) : url,
            method: 'get',
            data
        })
    }
}