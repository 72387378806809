/**
 * 基础配置
 */
export default {
    //本地环境基础url
    // baseUrl: "http://127.0.0.1:80/dev-api/",
    //公司服务器地址
    // baseUrl: "http://192.168.4.172:17071/stage-api/",
    // 生产环境服务器地址（生产环境无需IP和端口）
    baseUrl: "/stage-api/",
    //基础api url
    baseApiUrl: "ops/open/",
    //open api 密匙
    key: "ee82001536c0f0b1c8b729d188d1d69591ee51a459740445f456e9d56d8c0d85",
}
