<template>
  <!-- 网页头部组件 -->
  <div id="common_header">
    <!--  基本信息  -->
    <div class="header_inside">
      <!--   logo   -->
      <div class="header_logo">
        <el-link href="/" :underline="false">
          <el-image v-if="baseInfo.coLogo" style="width: 160px; height: 73px; border: 0" :src="preview + baseInfo.coLogo" fit="fill" />
        </el-link>
      </div>
      <!--   联系方式   -->
      <div class="header_tel" v-show="baseInfo.coLandline">
        <div class="tel_icon">
          <el-image style="height: 40px; border: 0" src="./statics/icon/components/header/tel.png" fit="fill" />
        </div>
        <div class="tel_text">{{ baseInfo.coLandline }}</div>
      </div>
    </div>
    <!--  头部tab切换栏  -->
    <div class="header_tab">
      <ul class="tab_ul">
        <!--    item.id == tabIndex 此处不能用=== 注意！    -->
        <el-link class="tab_a" @click="tabClick(item)" :class="[item.id == tabIndex ? 'headerTabAction' : '']" v-for="item in tabs" :key="item.id" :underline="false">
          <li class="tab_item">
            {{ item.name }}
          </li>
        </el-link>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderCompo",
  data() {
    return {
      //基础信息
      baseInfo: {},
      anjuLogo: "./temporary/logo.png",
      tabIndex: 1,
      //座机号码
      phone: "0769-26996666",
      //tab切换数组
      tabs: [
        {
          id: 1,
          path: "index",
          name: "网站首页"
        },
        {
          id: 2,
          path: "about",
          name: "关于安居"
        },
        {
          id: 3,
          path: "trends",
          name: "公司动态"
        },
        {
          id: 4,
          path: "project",
          name: "企业项目"
        },
        {
          id: 5,
          path: "regulations",
          name: "政策向导"
        },
        {
          id: 6,
          path: "bidInfo",
          name: "招标信息"
        },
        {
          id: 7,
          path: "humanResources",
          name: "人力资源"
        },
        {
          id: 8,
          path: "contact",
          name: "联系我们"
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.tabIndex = val.query.active || 1
        if (this.$route.matched && this.$route.matched.length > 0) {
          this.addAccessLog()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    //访问日志记录
    addAccessLog() {
      this.request.get("/puuv/accessLog?modular=" + this.tabs[this.tabIndex - 1].name + "&key=" + this.config.key).then((res) => {
        this.carousels = res.data
      })
    },
    //获取官网基本信息
    getInfo() {
      this.request.get("/baseinfo/getInfo?key=" + this.config.key).then((res) => {
        this.baseInfo = res.data
      })
    },
    //tab点击事件
    tabClick(item) {
      this.tabIndex = item.id
      this.$emit("headerCompoClick", item)
    }
  }
}
</script>
<style>
#common_header {
  height: 170px;
  line-height: 170px;
}

.header_logo,
.header_tel {
  height: 120px;
}

#common_header {
  width: 100%;
}

#common_header .header_inside {
  margin: auto;
  width: 1200px;
  height: 120px;
  display: flex;
  justify-content: space-between;
}

#common_header .header_logo {
  display: flex;
  width: 120px;
  margin-left: 10px;
  justify-items: center;
  align-items: center;
}

#common_header .header_tel {
  display: flex;
  align-items: center;
}

.header_tel .tel_text {
  font-size: 40px;
  margin-left: 5px;
}

.header_tab {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #5e5e5e;
}

.header_tab .tab_ul {
  display: flex;
  width: 1200px;
  margin: 0 auto;
}

.header_tab .tab_ul .tab_a {
  height: 50px;
  line-height: 50px;
  flex: 1;
}

.header_tab .tab_ul .tab_a:hover {
  background-color: #a7071d;
}

.header_tab .tab_ul .tab_a span,
.tab_item {
  width: 100%;
}

.header_tab .tab_ul .tab_a .tab_item {
  color: #fff;
  font-size: 18px;
  list-style: none;
  text-align: center;
}

.headerTabAction {
  background-color: #a7071d !important;
}
</style>