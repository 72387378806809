import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 公共路由
export const constantRoutes = [
  {
    path: "/",
    component: (resolve) => require(["@/pages/index"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/index",
    component: (resolve) => require(["@/pages/index"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/about",
    component: (resolve) => require(["@/pages/about"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/trends",
    component: (resolve) => require(["@/pages/trends"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/project",
    component: (resolve) => require(["@/pages/project"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/regulations",
    component: (resolve) => require(["@/pages/regulations"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/bidInfo",
    component: (resolve) => require(["@/pages/bidInfo"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/humanResources",
    component: (resolve) => require(["@/pages/humanResources"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/contact",
    component: (resolve) => require(["@/pages/contact"], resolve),
    hidden: true,
    meta: {
      keepAlive: true, //需要被缓存的组件
    },
  },
  {
    path: "/article",
    component: (resolve) =>
      require(["@/pages/details/articleDetails"], resolve),
    hidden: true,
    meta: {
      keepAlive: false, //不需要被缓存的组件
    },
  },
  {
    path: "/pid",
    component: (resolve) =>
      require(["@/pages/details/projectDetails"], resolve),
    hidden: true,
    meta: {
      keepAlive: false, //不需要被缓存的组件
    },
  },
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
