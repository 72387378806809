<template>
  <!-- 网页底部组件 -->
  <div id="common_bottom">
    <div class="bottom_top">
      <!--   logo   -->
      <div class="bottom_logo">
        <el-image v-if="baseInfo.coLogo" style="height: 118px" :src="preview + baseInfo.coLogo" fit="fill" />
      </div>
      <!--   友情链接   -->
      <div class="friendship_links">
        <el-link v-for="item in links" :key="item.id" :href="item.linkUrl" target="_blank" :underline="false"> {{ item.linkName }}&emsp; </el-link>
      </div>
    </div>
    <!--  版权信息  -->
    <div class="copyright_bottom">
      Copyright © 2018-2019&emsp;&emsp;东莞市安居建设投资有限公司&emsp;&emsp;版权所有&emsp;&emsp;
      <el-link class="copyright_a" href="https://beian.miit.gov.cn/" target="_blank" :underline="false">
        <el-image class="copyright_img" src="./statics/icon/components/bottom/beiAn.png" fit="fill" />
        粤ICP备19037469号
      </el-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomCompo",
  data() {
    return {
      baseInfo: {},
      anjuLogo: "./temporary/logo.png",
      //友情链接
      links: []
    }
  },
  created() {
    this.getInfo();
    this.getLinks()
  },
  methods: {
    //获取官网基本信息
    getInfo() {
      this.request.get("/baseinfo/getInfo?key=" + this.config.key).then((res) => {
        this.baseInfo = res.data
      })
    },
    //获取友情链接
    getLinks() {
      this.request.get("/link/list?key=" + this.config.key + "&pageNum=1&pageSize=50").then((res) => {
        this.links = res.data
      })
    }
  }
}
</script>
<style>
#common_bottom {
  width: 100%;
  background-color: #5e5e5e;
  color: #ffffff;
}

.bottom_top {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: start;
  padding-bottom: 15px;
}

.bottom_top .bottom_logo {
  width: 257px;
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  margin-top: 15px;
}

.bottom_top .friendship_links {
  width: 941px;
  padding-left: 80px;
}

.friendship_links a span {
  margin-top: 15px;
  color: #ffffff;
}

.friendship_links a span:hover {
  color: #a7071d;
}

.copyright_bottom {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-top: 1px solid #ffffff;
  text-align: center;
}

.copyright_bottom .copyright_a {
  color: #ffffff;
  height: 32px;
  line-height: 32px;
  border: 0;
}

.copyright_bottom .copyright_a:hover {
  color: #a7071d;
}

.copyright_img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}
</style>
