<template>
  <div id="app">
    <!--  头部  -->
    <HeaderCompo
        @headerCompoClick="headerCompoClick"
    />
    <div id="subject">
      <!--  会被缓存的组件  -->
      <keep-alive>
        <router-view
            v-if="this.$route.meta.keepAlive"/>
      </keep-alive>
      <!--  不被缓存的组件  -->
      <router-view
          v-if="!this.$route.meta.keepAlive"></router-view>
    </div>
    <!--  尾部  -->
    <BottomCompo/>
  </div>
</template>

<script>
import HeaderCompo from './components/HeaderCompo'
import BottomCompo from './components/BottomCompo'

export default {
  name: 'App',
  components: {
    HeaderCompo, BottomCompo
  },
  data() {
    return {
      //当前页面下标（从1开始）
      pageIndex: 1,
    }
  },
  methods: {
    /**
     *头部组件点击事件
     * @param item 参数
     */
    headerCompoClick(item) {
      this.$router.push({path: item.path, query: {'active': item.id}});
    },
  }
}
</script>

<style>
* {
  font-family: 微软雅黑 !important;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*font: 14px Helvetica Neue, Avenir, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;*/
}
</style>
